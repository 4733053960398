/*公共接口
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    CouponListApi: (data) => {//优惠劵列表
        return axios.get('/api/api/coupon/product', data);
    },
    CouponListApis: (data) => {//优惠劵列表
        return axios.get('/api/api/coupon/product/usable', data);
    },
    receiveCouponApi: (data) => {//领取优惠价
        return axios.post('/api/api/coupon/receive', data);
    },
    publicShareInfoApi: (type, goodsId) => {//公共分享获取信息接口 1代理2.VIP 4，5活动，6套餐,7栏目，8栏目视频，9训练营 25直播
        return axios.get('/products/custom-share/' + type + '/' + goodsId);
    },
    publicQnVideoInfo: (videoUrl) => {//获取七牛视频信息
        return axios.get(videoUrl + '?avinfo');
    },
    publicGoCustomer: () => {//公共客服是否 显示
        return axios.get('/api/api/help_center_question/floating/is-show');
    },
    publicPosterApi: (type, productId) => {//是否显示海报
        return axios.get(`/products/products/poster/exist?product_type=${type}&sub_product_id=${productId}`);
    },
    publicFootTab: () => {//底部TAB
        return axios.get('api/api/home/tab');
    },
    publicBatchUnderway: (data) => axios.get('/api/api/product/batch/underway', data),// 代理获取分批信息
    publicFormCode: (data) => axios.get('/api/api/universal-form/qr-code', data),// 获取产品关联表单
    publicTheirPrice: (data) => {//产品-到手价 约见 活动
        return axios.get('/products/products/best_prices', data);
    },
    publicGetInviteShowFn: (data) => axios.get(`/api/tools/spread/prod/if_set`, data),//获取是否显示海报
    publicMarquee: () => axios.get('/products/common/marquee'),// 跑马灯
}