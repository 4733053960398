/*活动相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    addRelativesTabApi: () => {//添加亲属Tab
        return axios.get('/api/api/teacher/activity/relatives/rel/type');
    },
    addRelativesApi: (data) => {//添加亲属
        return axios.post('/api/api/teacher/activity/relatives/add', data);
    },
    relativesListApi: () => {//亲属列表
        return axios.post('/api/api/teacher/activity/relatives/list');
    },
    relativesDetailsApi: (data) => {//亲属管理查看详情
        return axios.post('/api/api/teacher/activity/relatives/details', data);
    },
    delRelativesApi: (data) => {//删除亲属
        return axios.post('/api/api/teacher/activity/relatives/del', data);
    },
    // 判断亲属是否可以添加
    whetherAddApi: (data) => {//删除亲属
        return axios.post('/api/api/teacher/activity/relatives/judge', data);
    },
    acitvityListApi: (mechanismId, data) => {//活动列表
        return axios.requestGet('/v1/mechanisms/activity/' + mechanismId, data);
    },
    acitvityTabApi: () => {//活动列表tab
        return axios.get('/products/activity/categories/all');
    },
    acitvitySwpieApi: (data) => {//活动列表轮播
        return axios.get('/api/api/mechanism/banner', data);
    },
    getGiftInfo: (mechanismId, giftId) => {//转赠转卖 活动信息接口
        return axios.get('/api/api/resell-goods/intro/' + mechanismId + '/' + giftId);
    },
    putGift: (giftId, data) => {//转赠转卖 点击赠送和转卖时请求
        return axios.put('/api/api/resell-goods/' + giftId, data);
    },
    getGiftDetail: (giftId) => {//转赠转卖--领取活动 活动信息接口
        return axios.requestGet('/v1/actives/give-detail/' + giftId);
    },
    acitvityList: (data) => {//活动列表
        return axios.get('/products/activity/list', data);
    },
    // getDeliverable: (acitvityId, data) => {//活动交付区
    //     return axios.get('/products/activity/delivery_area/' + acitvityId, data);
    // },
    getDeliverable: (acitvityId, data) => {//活动交付区
        return axios.get('/products/activity/learn_area/' + acitvityId, data);
    },
    activityShareInfo: (data) => {//活动交付区-活动分享信息
        return axios.get('/api/api/mechanism/share_info/1', data);
    },
    activityMediaList: (mediaId) => {//活动交付区-音视频课件
        return axios.get('/products/activity/delivery_area/media/' + mediaId + '?version=2');
    },
    // activitySetTime: (data) => {//活动交付区-音视频课件
    //     return axios.post('/api/api/teacher/activity/video/play/update/time', data);
    // },
    activitySetTime: (data) => {//活动交付区-音视频课件
        return axios.post('/products/activity/learn_area/progress', data);
    },
    getFormInfo: (acitvityId, data) => {//活动报名信息
        return axios.get('/api/api/activity/fields/' + acitvityId, data);
    },
    submitFormInfo: (acitvityId, data) => {//活动报名信息
        return axios.post('/api/api/activity/submit-form/' + acitvityId, data);
    },
    activityRelative: (data) => {//活动亲属报名新增接口
        return axios.post('/api/api/teacher/activity/relative/deposit', data);
    },
    commentActivityInfo: (orderSn) => {//活动发布评价页-信息
        return axios.get('/products/activity/order/' + orderSn);
    },
    commentRelease: (acitvityId, data) => {//活动发布评价
        return axios.post('/products/activity/comments/' + acitvityId, data);
    },
    activityDetail: (acitvityId,data) => {//活动详情页-活动详情
        return axios.get('/products/activity/detail/' + acitvityId,data);
    },
    commentList: (acitvityId, data) => {//活动详情页-评论列表
        return axios.get('/products/activity/comments/' + acitvityId, data);
    },
    commentDelete: (commentId) => {//活动详情页-删除评论回复
        return axios.iDelete('/products/activity/comments/reply/' + commentId);
    },
    commentLove: (commentId) => {//活动评论-点赞和取消点赞
        return axios.post('/products/activity/love/' + commentId);
    },
    commentReply: (commentId, data) => {//活动评论-回复评论
        return axios.post('/products/activity/comment/reply/' + commentId, data);
    },
    commentDetail: (commentId) => {//活动评论-评论详情
        return axios.get('/products/activity/comment/' + commentId);
    },
    getClassNum: (acitvityId, data) => {//活动--获取报名人数和到手价
        return axios.get('/products/activity/detail/class_num/' + acitvityId, data);
    },
    joinAuth: (data) => {//活动-报名权限
        return axios.post('/api/api/teacher/activity/join_authority', data);
    },
    signInfoActivityInfo: (data) => {//活动填写信息页-活动信息
        return axios.get('/products/activity/orderinfo', data);
    },
    // activityTheirPrice: (data) => {//活动填写信息页-到手价
    //     return axios.get('/products/products/best_prices', data);
    // }, 迁移至api_product.js publicTheirPrice
    giveActivityReceive: (acitvityId,data) => {//活动填写信息页-；赠送的领取
        return axios.requestPost('/v1/ucentor/actives/perfect/'+acitvityId, data);
    },
    commentEdit: (commentId, data) => {//活动编辑评价
        return axios.put('/products/activity/comments/' + commentId, data);
    },
}