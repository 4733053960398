// import { api_common } from '@/api/interface/index'
import { commonShare } from './share'
// import storage from '@/common/uilt/storage';
import fnIcon from '@/common/website/website-icon';
import store from '@/store/index'
export default async function publicGetShareInfo(to, shareInfo, shareName = "shareId") {
    //如果没有传值，默认值为空对象
    let shareId = store.state.share.shareId;
    if (!shareInfo) {
        shareInfo = {};
    }
    let groupInfo = store.state.loginInfo.mechanismInfo;
    let userInfo = store.state.loginInfo.userInfo;
    let uid, is_agent, inviteUser
    if (userInfo) {
        uid = userInfo.uid;//用户id
        is_agent = userInfo.is_agent;//0不是代理1是代理
        inviteUser = userInfo.inviteUser//邀请人信息
    }
    let shareTitle, //分享标题  
        shareUrl,// 分享链接
        shareImg,//分享图标
        shareDesc //分享描述
    //判断如果是自定义分享信息 type 1使用机构名称加参数
    shareTitle = groupInfo.name;// 分享标题
    shareUrl = groupInfo.domain + to.path + '?'; // 分享链接
    shareImg = groupInfo.logo; // 分享图标
    shareDesc = groupInfo.slogan;// 分享描述
    if (groupInfo.logo) {
        fnIcon(groupInfo.logo)//网站图标
    }
    if (shareInfo.type == 1) {
        shareTitle = groupInfo.name + shareInfo.title1;
    } else {
        if (shareInfo.title1) {
            shareTitle = shareInfo.title1;
        }
        if (shareInfo.title2) {
            shareDesc = shareInfo.title2;
        }
        if (shareInfo.title2 == 'gift') {
            shareDesc = '';
        }
        if (shareInfo.imgSrc) {
            shareImg = shareInfo.imgSrc;
        }
    }
    if (to.query) {
        delete to.query.code;
        delete to.query.state;
        delete to.query.appid;
        for (let key in to.query) {
            if (key !== 'shareId') {
                shareUrl += key + '=' + to.query[key] + '&';
            }
        }
    }
    if (is_agent == 1) {
        // 当前用户是代理
        shareUrl += shareName + '=' + uid + '&';
    } else {
        if (shareId > 0 && shareId != uid) {
            // 如果页面链接上携带了share_id的值
            shareUrl += shareName + '=' + shareId + '&';
        } else {
            //  当前用户不是代理
            if (groupInfo.invite_type == 1) {
                // 1官方
                shareUrl += shareName + '=' + '&';
            } else if (groupInfo.invite_type == 2) {
                //2上级
                shareUrl += shareName + '=' + inviteUser + '&';
            }
        }
    }
    // 加参数判断是否点击分享进的页面
    shareUrl += 'isFirst=1'
    commonShare(shareTitle, shareUrl, shareImg, shareDesc)  
    
}