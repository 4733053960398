/*我的微信
i:get || post
h:requestGet ||requestPost
*/
import axios from '../../axios'
export default {
    getInfo: () => {//获取信息
        return axios.get('/api/api/user/profile');
    },
    postInfo: (data) => {//设置信息
        return axios.post('/api/api/user/update_wechat',data);
    },
    postImg: (data) => {//上传图片
        return axios.post('/api/mechanismapi/upload/image',data);
    },
    getContactInfo: () => {//企业微信信息
        return axios.get('/products/agent/contact-info');
    }
}