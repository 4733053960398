/** 部落 */
export default [
    {
        path: 'depositClass/list',
        name: 'list',
        meta: {
            title: '订金课',
        },
        component: () => import('@/views/depositClass/list.vue')
    }, {
        path: 'depositClass/index',
        name: 'index',
        meta: {
            // title: '订金课',
        },
        component: () => import('@/views/depositClass/index.vue')
    }, {
        path: 'depositClass/depositMes',
        name: 'depositMes',
        meta: {
            // title: '订金课名称',
        },
        component: () => import('@/views/depositClass/depositMes.vue')
    }

    
]