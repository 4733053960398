/** 任务相关 */
export default [
    {
        path: 'task/taskList',
        name: 'task-list',
        meta: {
            title: '打卡',
            isShowTab: false,
        },
        component: () => import('@/views/task/taskList.vue')
    },
    {
        path: 'task/remove',
        name: 'remove',
        meta: {
            title: '清除',
            isShowTab: false,
            isLoading:true
        },
        component: () => import('@/views/task/remove.vue')
    },
    {
        path: 'task/posterIndex',
        name: 'posterIndex',
        meta: {
            title: '海报',
            isShowTab: false,
            isLoading:true
        },
        component: () => import('@/views/task/posterIndex.vue')
    },
    {
        path: 'task/taskPdf',
        name: 'taskPdf',
        meta: {
            title: '查看课件',
            isShowTab: false,
            isLoading:false
        },
        component: () => import('@/views/task/taskPdf.vue')
    },
]