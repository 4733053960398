/*企微授权
i:get || post
h:requestGet ||requestPost
*/
import baseURL from '../config';
import axios from 'axios'
const instance = axios.create({
    baseURL: baseURL.new_api_url
  })
export default {
    getAuthData: () => { // 获取授权基本数据
        return instance.get(`/api/tools/enterprise-wx/suites/auth-params`);
    },
    getSuiteAuth: (data) => {//授权登录
        return instance.post('/api/tools/enterprise-wx/suites/auths', data);
    },
    getSuiteBindTel: (data, id) => {//绑定手机号
        return instance.post('/api/tools/enterprise-wx/suites/auth-binds', data, {
            headers: {
                'X-Mechanism-Id': id 
            }
        });
    },
    getJsApi: (data, id) => {//获取通讯录组件授权
        return instance.post('/api/tools/enterprise-wx/suites/jsapi-params', data, {
            headers: {
                'X-Mechanism-Id': id 
            }
        });
    },
    getSmsToken: (data, id) => {//获取验证码token
        return instance.post("/api/api/get_sms_token", data, {
            headers: {
                'X-Mechanism-Id': id 
            }
        });
    },
    getSmsData: (data, id) => {//获取验证码
        return instance.post("/api/api/get_sms_code", data, {
            headers: {
                'X-Mechanism-Id': id 
            }
        });
    },
    // 获取欢迎语详情
    getWelcomeDetail: (data) => {
      return instance.get("/api/tools/enterprise-wx/suites/wel-messages/" + data);
    },
}