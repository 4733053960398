import axios from 'axios';
import storage from '@/common/uilt/storage';
import baseURL from './config';
import VueCookies from 'vue-cookies'//vue-cookies插件
import Vue from 'vue'
import store from '../store'
import { Dialog } from 'vant';
const service = axios.create();
//请求拦截
service.interceptors.request.use(function (config) {
    const mechanismInfo = store.state.loginInfo.mechanismInfo;
    const mechanism_id = mechanismInfo ? mechanismInfo.mechanism_id : ''
    const token = storage.getItem('token') || VueCookies.get('token');
    if (/get/i.test(config.method)) { //判断get请求
        config.params = config.params || {};
        config.params.t = Date.parse(new Date()) / 1000; //添加时间戳
    }
    if (process.env.NODE_ENV === 'development') {
        config.headers['X-Mechanism-Id'] = 3194;
    } else if (process.env.VUE_APP_CURRENTMODE === 'test') {
        // 如果是企微跳转的页面，因为企微跳转的链接【不携带机构域名】，需要单独获取然后赋值请求头
        if (localStorage.getItem('isWecomPage')) {
            config.headers['X-Mechanism-Id'] = localStorage.getItem("wecom_mechanism_id")
        } else if (mechanism_id) {
            config.headers['X-Mechanism-Id'] = mechanism_id;
        }
        
        // else{
        //     config.headers['X-Mechanism-Id'] = 3194;
        // }
    } else {
        if (mechanism_id) {
            config.headers['X-Mechanism-Id'] = mechanism_id;
        }
    }
    if (token) {
        config.headers['Authorization'] = "Bearer " + token;
    }
    config.headers['source'] = "wap";
    return config
}, function (error) {
    // 请求错误时弹框提示，或做些其他事
    return Promise.reject(error)
});
//响应拦截
service.interceptors.response.use(function (response) {
    return response.data
}, function (error) {
    if (error?.response?.status == 406) {
        Vue.showAuthority();
    }
    // 对响应错误做点什么
    Vue.closeLoading();
    let originalRequest = error.config
    if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1 && !originalRequest._retry && originalRequest.url != '/api/api/request/log') {
        Dialog.alert({
            title: '提示',
            message: '网络请求失败，请检查您的网络',
            showCancelButton: true,
            cancelButtonColor: '#ee0a24',
            confirmButtonColor: "#1176FF",
            confirmButtonText: '重新加载'
        }).then(() => {
            window.location.reload();
        });
    }
    return Promise.reject(error)
});
// 封装数据返回失败提示函数
function errorState(response) {
    // 如果http状态码正常，则直接返回数据
    if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
        // 如果不需要除了data之外的数据，可以直接 return response.data
        return response
    }
}
// 封装数据返回成功提示函数
function successState() {
}
// 封装axios
function apiAxios(method, url, data) {
    let httpDefault = {
        method: method,
        baseURL: baseURL.new_api_url,
        url: url,
        // `params` 是即将与请求一起发送的 URL 参数
        // `data` 是作为请求主体被发送的数据
        params: method === 'GET' || method === 'DELETE' ? data : null,
        data: method === 'POST' || method === 'PUT' ? data : null,
        timeout: 60000
    }
    return new Promise((resolve, reject) => {
        service(httpDefault)
            // 此处的.then属于axios
            .then((res) => {
                successState(res)
                resolve(res)
            }).catch((response) => {
                errorState(response)
                reject(response)
            })
    })
}
// 封装axios
function requestApi(method, url, data) {
    let httpDefault = {
        method: method,
        baseURL: baseURL.api_url,
        url: url,
        // `params` 是即将与请求一起发送的 URL 参数
        // `data` 是作为请求主体被发送的数据
        params: method === 'GET' || method === 'DELETE' ? data : null,
        data: method === 'POST' || method === 'PUT' ? data : null,
        timeout: 60000
    }
    return new Promise((resolve, reject) => {
        service(httpDefault)
            // 此处的.then属于axios
            .then((res) => {
                successState(res)
                resolve(res)
            }).catch((response) => {
                errorState(response)
                reject(response)
            })
    })
}
// 封装axios
function socketApi(method, url, data) {
    let httpDefault = {
        method: method,
        baseURL: baseURL.socket_api_url,
        url: url,
        // `params` 是即将与请求一起发送的 URL 参数
        // `data` 是作为请求主体被发送的数据
        params: method === 'GET' || method === 'DELETE' ? data : null,
        data: method === 'POST' || method === 'PUT' ? data : null,
        timeout: 60000
    }
    return new Promise((resolve, reject) => {
        service(httpDefault)
            // 此处的.then属于axios
            .then((res) => {
                successState(res)
                resolve(res)
            }).catch((response) => {
                errorState(response)
                reject(response)
            })
    })
}
function tarzanApi(method, url, data) {
    let httpDefault = {
        method: method,
        baseURL: baseURL.tarzan,
        url: url,
        // `params` 是即将与请求一起发送的 URL 参数
        // `data` 是作为请求主体被发送的数据
        params: method === 'GET' || method === 'DELETE' ? data : null,
        data: method === 'POST' || method === 'PUT' ? data : null,
        timeout: 60000
    }
    return new Promise((resolve, reject) => {
        service(httpDefault)
            // 此处的.then属于axios
            .then((res) => {
                successState(res)
                resolve(res)
            }).catch((response) => {
            errorState(response)
            reject(response)
        })
    })
}
// 输出函数getAxios、postAxios、供其他文件调用
export default {
    get: (url, data) => apiAxios('GET', url, data),
    post: (url, data) => apiAxios('POST', url, data),
    iDelete: (url, data) => apiAxios('DELETE', url, data),
    put: (url, data) => apiAxios('PUT', url, data),
    requestGet: (url, data) => requestApi('GET', url, data),
    requestPost: (url, data) => requestApi('POST', url, data),
    hDelete: (url, data) => requestApi('DELETE', url, data),
    socketGet: (url, data) => socketApi('GET', url, data),
    tarzantPost: (url, data) => tarzanApi('POST', url, data),
    tarzantGet: (url, data) => tarzanApi('GET', url, data),
}

