/** 训练营相关 */
export default [
    // 训练营详情
    {
        path: 'train/index',//训练营- 营期课表 / 详情介绍
        name: 'campPeriod',
        meta: {
            title: '训练营',
        },
        component: () => import('@/views/train/index.vue')
    },
    {
        path: 'train/work',//我的作业 / 同学作业
        name: 'trainWork',
        meta: {
            title: '',
            keepAlive:true,
            backSource:'', //用于判断上一个页面是哪个
        },
        component: () => import('@/views/train/work.vue')
    },
    {
        path: 'train/workDetail',//训练营-作业详情
        name: 'workDetail',
        meta: {
            title: '作业详情',
            
        },
        component: () => import('@/views/train/workDetail.vue')
    },
    {
        path: 'train/workCalendar',//训练营-作业日历
        name: 'workCalendar',
        meta: {
            title: '',
        },
        component: () => import('@/views/train/workCalendar.vue')
    },
    {
        path: 'train/lesson',//训练营-小灶课
        name: 'trainLesson',
        meta: {
            title: '小灶课',
        },
        component: () => import('@/views/train/lesson.vue')
    },
    {
        path: 'train/edit',//训练营-编辑作业
        name: 'trainEdit',
        meta: {
            title: '打卡',
        },
        component: () => import('@/views/train/edit.vue')
    },
    {
        path: 'train/record',//训练营-编辑作业
        name: 'trainRecord',
        meta: {
            title: '打卡记录',
        },
        component: () => import('@/views/train/record.vue')
    },
    {
        path: 'train/shareDetails',//分享详情生成图片
        name: 'shareDetails',
        meta: {
            title: '',
        },
        // component: () => import('@/views/task/taskList.vue')
    },
    {
        path: 'train/signInfo',//报名
        name: 'trainsignInfo',
        meta: {
            title: '填写报名信息',
        },
        component: () => import('@/views/train/signInfo.vue')
    },
    {
        path: 'train/stove',//小灶课
        name: 'trainStove',
        meta: {
            title: '',
        },
        // component: () => import('@/views/task/taskList.vue')
    },

    {
        path: 'train/homeworkCalendar',//作业日历
        name: 'homeworkCalendar',
        meta: {
            title: '',
        },
        // component: () => import('@/views/task/taskList.vue')
    },
    {
        path: 'train/clock',//打卡
        name: 'trainClock',
        meta: {
            title: '',
        },
        // component: () => import('@/views/task/taskList.vue')
    },
    {
        path: 'train/clockRecord',//打卡记录
        name: 'clockRecord',
        meta: {
            title: '',
        },
        // component: () => import('@/views/task/taskList.vue')
    },
    {
        path: 'train/students/myClass',//我的班级
        name: 'trainMyClass',
        meta: {
            title: '我的班级',
        },
        component: () => import('@/views/train/students/myClass.vue')
    },
    {
        path: 'train/students/invitationList',//邀请榜
        name: 'invitationList',
        meta: {
            title: '邀请榜',
        },
        component: () => import('@/views/train/students/invitationList.vue')
    },
    {
        path: 'train/students/InvitedRecord',//邀请记录
        name: 'InvitedRecord',
        meta: {
            title: '邀请记录',
        },
        component: () => import('@/views/train/students/InvitedRecord.vue')
    },
    {
        path: 'train/students/ranking',//排行榜
        name: 'trainRanking',
        meta: {
            title: '排行榜',
        },
        component: () => import('@/views/train/students/ranking.vue')
    },
    {
        path: 'train/mentor/amTeacher',//我是导师
        name: 'amTeacher',
        meta: {
            title: '我是导师',
        },
        component: () => import('@/views/train/mentor/amTeacher.vue')
    },
    {
        path: 'train/mentor/teacherHome',//导师主页
        name: 'teacherHome',
        meta: {
            title: '我是导师',
        },
        component: () => import('@/views/train/mentor/teacherHome.vue')
    },
    {
        path: 'train/mentor/distribution',//分导师
        name: 'distribution',
        meta: {
            title: '分导师',
        },
        component: () => import('@/views/train/mentor/distribution.vue')
    },
    {
        path: 'train/mentor/hesTeacher',//TA的导师
        name: 'hesTeacher',
        meta: {
            title: 'TA的导师',
        },
        component: () => import('@/views/train/mentor/hesTeacher.vue')
    },
    {
        path: 'train/mentor/teacherDetails',//导师详情
        name: 'teacherDetails',
        meta: {
            title: '导师详情',
        },
        component: () => import('@/views/train/mentor/teacherDetails.vue')
    },
    {
        path: 'train/mentor/commentStudent',//点评学员
        name: 'commentStudent',
        meta: {
            title: '点评学员',
        },
        component: () => import('@/views/train/mentor/commentStudent.vue')
    },
    {
        path: 'train/mentor/homework',//详细数据
        name: 'homework',
        meta: {
            title: '详细数据',
        },
        component: () => import('@/views/train/mentor/homework.vue')
    },
    {
        path: 'train/mentor/management',//班级管理
        name: 'management',
        meta: {
            title: '',
        },
        component: () => import('@/views/train/mentor/management.vue')
    },
    {
        path: 'train/mentor/export',//导出数据
        name: 'mentorExport',
        meta: {
            title: '导出数据',
        },
        component: () => import('@/views/train/mentor/export.vue')
    },
    {
        path: 'train/mentor/serviceRevenue',//服务收益
        name: 'serviceRevenue',
        meta: {
            title: '服务收益',
        },
        component: () => import('@/views/train/mentor/serviceRevenue.vue')
    },
    {
        path: 'train/mentor/earningsDetails',//收益查看详情
        name: 'earningsDetails',
        meta: {
            title: '查看详情',
        },
        component: () => import('@/views/train/mentor/earningsDetails.vue')
    },
    {
        path: 'train/mentor/conversionRules',//转化规则
        name: 'conversionRules',
        meta: {
            title: '转化规则',
        },
        component: () => import('@/views/train/mentor/conversionRules.vue')
    },
    {
        path: 'train/students/integral',//积分明细
        name: 'integral',
        meta: {
            title: '积分明细',
        },
        component: () => import('@/views/train/students/integral.vue')
    },
    {
        path: 'train/shareMaterial/signFigure',//分享素材
        name: 'signFigure',
        meta: {
            title: '分享素材',
        },
        component: () => import('@/views/train/shareMaterial/signFigure.vue')
    },
    {
        path: 'train/trainList',//训练营列表
        name: 'trainList',
        meta: {
            title: '训练营',
        },
        component: () => import('@/views/train/trainList.vue')
    },
    {
        path: 'train/mentor/checkCalendar',//导师端查看日历
        name: 'checkCalendar',
        meta: {
            title: '',
        },
        component: () => import('@/views/train/mentor/checkCalendar.vue')
    },
    {
        path: 'train/bonus/depositDetail',//押金明细
        name: 'depositDetail',
        meta: {
            title: '押金明细',
        },
        component: () => import('@/views/train/bonus/depositDetail.vue')
    },
    {
        path: 'train/bonus/index',//押金明细
        name: 'bonusIndex',
        meta: {
            title: '奖金池',
        },
        component: () => import('@/views/train/bonus/index.vue')
    },
    {
        path: 'train/pdf',//训练营-pdf文档查看
        name: 'trainPdf',
        meta: {
            title: '文档',
        },
        component: () => import('@/views/train/pdf.vue')
    },
    {
        path: 'train/tutorCalendar',//训练营-导师日历
        name: 'pdf',
        meta: {
            title: '日历',
        },
        component: () => import('@/views/train/tutorCalendar.vue')
    },
    {
        path: 'train/mentor/searchStudents',//搜索学员
        name: 'searchStudents',
        meta: {
            title: '搜索学员',
        },
        component: () => import('@/views/train/mentor/searchStudents.vue')
    },
    {
        path: 'train/students/course',//课表
        name: 'searchScourse',
        meta: {
            title: '课表',
        },
        component: () => import('@/views/train/students/course.vue')
    },
    {
        path: 'train/preview',//课表
        name: 'searchPreview',
        meta: {
            title: '',
        },
        component: () => import('@/views/train/preview.vue')
    },
    {
        path: 'train/medalDetails',//勋章详情页
        name: 'medalDetails',
        meta: {
            title: '',
        },
        component: () => import('@/views/train/medalDetails.vue')
    },
    {
        path: 'train/noPermission',//无权限页
        name: 'noPermission',
        meta: {
            title: '',
        },
        component: () => import('@/views/train/noPermission.vue')
    },
    {
        path: 'train/expired',//训练营过期页面
        name: 'expired',
        meta: {
            title: '',
        },
        component: () => import('@/views/train/expired.vue')
    },
    {
        path: 'common/comment/release',//训练营-去评价
        name: 'commonCommentRelease',
        meta: {
            title: '发布评价',
        },
        component: () => import('@/components/commentCommon/release.vue')
    },
    {
        path: 'common/comment/detail',//训练营-评价详情
        name: 'commonCommentDetail',
        meta: {
            title: '评论详情',
        },
        component: () => import('@/components/commentCommon/detail.vue')
    },
    



    
    // 闯关的页面

]