/* 赠好友 */
export default [
  {
    path: 'gift/detail',
    name: 'giftDetail',
    meta: {
      title: '赠好友',
    },
    component: () => import('@/views/gift/detail.vue')
  },
  {
    path: 'gift/receive',
    name: 'giftReceive',
    meta: {
      title: '赠好友',
    },
    component: () => import('@/views/gift/receive.vue')
  },
  {
    path: 'gift/info',
    name: 'giftInfo',
    meta: {
      title: '免费赠好友',
    },
    component: () => import('@/views/gift/info.vue')
  },
]