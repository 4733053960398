/*套餐相关*/
/* keepAlive:该页面是否缓存 */
export default [
    {
        path: 'chart/lineChart',
        name: 'lineChart',
        meta: {
            title: '折线图',
            keepAlive: false,
        },
        component: () => import('@/views/chart/lineChart.vue')
    }
]